@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&subset=latin-ext');
/*
	Jakub Grzywacz 2018 All rights reserved
*/

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	font-family: 'Quicksand', sans-serif;
	color: #2a2a2a;
	word-break: break-word
}
.lines {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	.line {
		width: calc(100% / 3);
		height: 4px;
		float: left;
		&:nth-of-type(1) {
			background-color: #25B246;
		}
		&:nth-of-type(2) {
			background-color: #E59A30;
		}
		&:nth-of-type(3) {
			background-color: #E54830;
		}
	}
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}
.logo-container {
	text-align: center;
	padding-top: 4rem;
	padding-bottom: 4rem;
	.logo {
		margin-bottom: 1rem;
		img {
			width: auto;
			max-width: 450px;
		}
	}
	.text {
		h1 {
			font-size: 2.4rem;
			font-weight: 400;
		}
	}
}
@media screen and (max-width: 540px) {
	.logo-container {
		.logo {
			padding-left: 2rem;
			padding-right: 2rem;
			img {
				max-width: 100%;
			}
		}
	}
}
.container {
	width: 90%;
	max-width: 1175px;
	margin-right: auto;
	margin-left: auto;
}
section {
	background-color: #f5f5f5;
	padding: 2rem;
	margin-bottom: 2rem;
	.title {
		display: inline;
		padding: 2px;
		font-size: 1.2rem;
		font-weight: 500;

		&.one {
			background-color: #25B246;
		}
		&.two {
			background-color: #E59A30;
		}
		&.three {
			background-color: #E54830;
		}
	}
	.content {
		margin-top: 1rem;
	}
}
.cards {
	&::after {
		content: '';
		display: block;
		clear: both;
	}
	.card {
		padding: 2rem 2rem 0 2rem;
		text-align: center;

		&.card-one-of-three {
			width: calc(100% / 3);
			float: left;
		}
		.icon {
			margin-bottom: 2rem;
			i {
				font-size: 4rem;
			}
		}
		.card-content {
			ul {
				list-style: none;

				li {
					padding-bottom: 1rem;
					margin-bottom: 1rem;
					border-bottom: 1px solid rgba(0, 0, 0, .3);
				}
			}
		}
	}
}
section.kontakt {
	.content {
		padding-top: 1rem;
		.map {
			width: 50%;
			height: 500px;
			float: left;
		}
		.contact {
			width: 50%;
			float: left;
			padding: 0 2rem;
			.item {
				
				margin-bottom: 2rem;
				.icon {
					display: inline;
					i {
						transform: translateY(25%);
						font-size: 2rem;
					}
				}
				.var {
					display: inline;
					font-size: 1.2rem;
					// word-break: break-all;
					a {
						text-decoration: none;
						color: inherit;
					}
				}
			}
		}
		&::after {
			content: '';
			display: block;
			clear: both;
		}
	}
}
footer {
	text-align: right;
	margin-bottom: 2rem;
	font-size: .8rem;
	a {
		color: #2CA7D4;
		text-decoration: none;
	}
}
@media screen and (max-width: 960px) {
	.cards {
		.card.card-one-of-three {
			width: 100%;
			float: unset;
		}
	}
	section.kontakt {
		.content {
			padding-top: 1rem;
			.map {
				width: 100%;
				float: none;
			}
			.contact {
				margin-top: 2rem;
				width: 100%;
				float: none;
			}
		}
	}
}